import { getContentfulImageProps } from 'src/cms/utils/utils';
import type { IImageFields } from 'types/generated/contentful';
import { DecoratedNextImage } from './DecoratedNextImage';

interface ContentfulImageT {
  image: IImageFields;
}

export const ContentfulNextImage = ({ image, ...rest }: ContentfulImageT) => (
  <DecoratedNextImage {...getContentfulImageProps(image)} {...rest} />
);

export default ContentfulNextImage;
