import React, { type JSX } from 'react';
import { variants, type IconVariantProps } from './variants';

function RadioButtonIcon({ size }: IconVariantProps): JSX.Element {
  return (
    <Wrapper id="radiobutton" size={size}>
      <circle fill="var(--color-on-secondary)" cx="12" cy="12" r="5" />
    </Wrapper>
  );
}

export function CheckboxIcon({ size }: IconVariantProps): JSX.Element {
  return (
    <Wrapper id="checkbox" size={size}>
      <path
        fill="var(--color-on-secondary)"
        d="M20.268 7.031 9.562 17.737l-5.83-5.83L5.5 10.138 9.563 14.2 18.5 5.263l1.768 1.768Z"
      />
    </Wrapper>
  );
}

const IndeterminateIcon = ({ size }: IconVariantProps): JSX.Element => {
  return (
    <Wrapper id="indeterminate" size={size}>
      <path fill="var(--color-on-secondary)" d="M4 10h16v4H4z" />
    </Wrapper>
  );
};

const Wrapper = ({
  id,
  size,
  children,
}: {
  id: string;
  size: IconVariantProps['size'];
  children: any;
}): JSX.Element => {
  return (
    <svg
      className={variants.icon({ size })}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={`${id}-icon`}
    >
      {children}
    </svg>
  );
};

export const Icon = ({
  type,
  indeterminate,
  size,
  checked,
}: {
  type: 'checkbox' | 'radio';
  indeterminate: boolean;
  size: IconVariantProps['size'];
  checked: boolean | undefined;
}) => {
  switch (type) {
    case 'checkbox':
      if (indeterminate) return <IndeterminateIcon size={size} />;
      if (checked) return <CheckboxIcon size={size} />;
      else return;
    case 'radio':
      if (checked) return <RadioButtonIcon size={size} />;
      return;
    default:
      return;
  }
};
