import React from 'react';
import { Box, CheckItem, type BoxT, Label } from '@mentimeter/ragnar-ui';
import { Field, type FieldProps } from 'formik';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { getErrorMessage, getShouldShowError } from '../utils';
import { InputFeedback } from '../input-feedback';

export interface CheckT extends BoxT {
  id: string;
  name: string;
  label?:
    | string
    | React.ReactNode
    | (({ checked }: { checked: boolean }) => React.ReactNode)
    | undefined;
  hintText?: string | undefined;
  validate?: (value: any) => void | string | Promise<any>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  innerRef?: any;
  type?: 'checkbox' | 'radio' | undefined;
  value?: string | undefined;
  feedbackAlwaysTakeSpace?: boolean;
}

export function Check({
  name,
  label,
  type = 'checkbox',
  validate,
  innerRef,
  hintText,
  onBlur,
  onChange,
  id,
  children,
  value,
  feedbackAlwaysTakeSpace = true,
  className,
  ...boxProps
}: CheckT) {
  return (
    <Field name={name} validate={validate} innerRef={innerRef}>
      {(fieldProps: FieldProps) => {
        const shouldShowErrorMessage = getShouldShowError(fieldProps);
        const checked =
          type === 'checkbox'
            ? Boolean(fieldProps.field.value)
            : fieldProps.field.value === value;

        return (
          <Box className={clsx(className)} {...boxProps}>
            <Box className="flex-row items-center w-full">
              <CheckItem
                name={fieldProps.field.name}
                variant={shouldShowErrorMessage ? 'negative' : undefined}
                id={id}
                aria-describedby={`input-feedback-${id}`}
                type={type}
                value={value}
                checked={checked}
                onBlur={(e) => {
                  fieldProps.field.onBlur(e);
                  if (onBlur) onBlur(e);
                }}
                onChange={(e) => {
                  fieldProps.field.onChange(e);
                  if (onChange) onChange(e);
                }}
              />

              {children}

              {label && typeof label === 'function' && label({ checked })}
              {label && typeof label !== 'function' && (
                <Label htmlFor={id} className="mx-2 mb-0">
                  {label}
                </Label>
              )}
            </Box>
            <InputFeedback
              error={getErrorMessage(fieldProps)}
              showError={shouldShowErrorMessage}
              hint={hintText}
              alwaysTakeSpace={feedbackAlwaysTakeSpace}
              id={`input-feedback-${id}`}
            />
          </Box>
        );
      }}
    </Field>
  );
}
